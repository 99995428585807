import {Dispatch, SetStateAction} from "react";

const Checkbox = ({ checked, changeHandler, wrapperClass, checkboxClassName , id}: { checked: boolean, changeHandler: Dispatch<SetStateAction<any>>, wrapperClass?: string,checkboxClassName?: string, id?:string }) => {
  return (
      <>
        <div
            className={`relative h-2 w-2 rounded-sm bg-white border border-primary p-2 cursor-pointer ${wrapperClass}`}
            onClick={() => changeHandler(!checked)}
            id = {id}
        >
          <div
              className={`absolute h-[10px] w-[5px] ${checked && "border-primary border-b-2 border-r-2"}  top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4 rotate-45 ${checkboxClassName}`}
          />
        </div>
      </>
  );
};

export default Checkbox;
