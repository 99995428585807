import { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router";
import {
    compareFace,
    getUserDetails,
    saveLivenessDetails,
} from "../../apis/coApplicant";
import { showToast } from "../../utils/toast";
import { toastTypes } from "../../utils/reusableConst";
import { MainContext } from "../../routes";

const useGetLivenessCheck = () => {
    const [openCamera, setOpenCamera] = useState(false);
    const [activeLivenessPage, setActiveLivenessPage] = useState(0);
    const [userDetails, setUserDetails] = useState(null);
    const { coApplicantDetails } = useContext(MainContext);
    const webcamRef = useRef<any>(null);
    const navigate = useNavigate();

    const handleTakePhoto = async (dataUri: string) => {
        return await getUserDetails({
            faceScanBase64: dataUri.split(",")[1],
            loanApplicantId:
                coApplicantDetails?.maainApplicantName?.loanApplicantId,
        });
    };

    const confirmLivenessDetails = async (payload: any) => {
        const body = {
            isAlive: payload?.isLive,
            alivenessConfidence: payload?.confidence,
            faceScanBase64: payload?.dataUri,
        };
        const response = await saveLivenessDetails(body);

        if (response?.success) {
            // call the compare face api
            const compareFaceResponse = await compareFace({
                body: {
                    faceScanBase64: payload?.dataUri,
                },
            });

            if (compareFaceResponse?.status === 200) {
                if (compareFaceResponse?.data?.data?.isMatch) {
                    showToast(
                        "Photo submitted successfully",
                        toastTypes.success
                    );
                    navigate("/verify-details");
                } else {
                    showToast("Face match failed", toastTypes.error);
                }
            } else {
                showToast("Face match failed", toastTypes.error);
            }
        } else {
            showToast("Failed to submit photo", toastTypes.error);
        }
    };

    return {
        setOpenCamera,
        openCamera,
        navigate,
        handleTakePhoto,
        activeLivenessPage,
        setActiveLivenessPage,
        userDetails,
        setUserDetails,
        webcamRef,
        confirmLivenessDetails,
    };
};

export default useGetLivenessCheck;
